import { ServiceStationStorageService } from 'services/service-station-storage-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { StorageHistoryService } from 'services/storage-history-service';
import { StorageInvoiceService } from 'services/storage-invoice-service';
import { ToastService } from 'services/toast-service';
import moment from 'moment';
import { StorageInvoiceDetailsDialog } from './storage-invoice-details-dialog';
import { ErrorService } from 'services/error-service';
import './storage-details-dialog.scss';

@autoinject
export class StorageDetailsDialog {
  private storageHistory: Models.StorageHistory;
  private serviceStationStorages: Models.ServiceStationStorage[];

  timeSpentInDisabled = false;
  timeSpentOutDisabled = false;

  constructor(
    private dialogController: DialogController,
    private storageHistoryService: StorageHistoryService,
    private serviceStationStorageService: ServiceStationStorageService,
    private storageInvoiceService: StorageInvoiceService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService
  ) {}

  async activate(params) {
    const storageHistory = JSON.parse(JSON.stringify(params.storageHistory));
    this.storageHistory = {
      ...storageHistory,
      TimeStartIn: storageHistory.TimeStartIn ? moment(storageHistory.TimeStartIn).format('HH:mm') : null,
      TimeEndIn: storageHistory.TimeEndIn ? moment(storageHistory.TimeEndIn).format('HH:mm') : null,
      TimeStartOut: storageHistory.TimeStartOut ? moment(storageHistory.TimeStartOut).format('HH:mm') : null,
      TimeEndOut: storageHistory.TimeEndOut ? moment(storageHistory.TimeEndOut).format('HH:mm') : null,
    };
    await this.getServiceStationStorages();
  }

  private async openStorageInvocice(id: number) {
    try {
      this.router.navigateToRoute('storage-invoice-list');
      await this.dialogController.close(true);
      const row = await this.storageInvoiceService.get(id);

      await this.dialogService.open({
        viewModel: StorageInvoiceDetailsDialog,
        model: { storageInvoice: row },
        lock: false,
        keyboard: true,
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private async getServiceStationStorages() {
    const storages = await this.serviceStationStorageService.getAllCached();
    this.serviceStationStorages = storages.filter((it) => !it.IsDeleted);

    this.timeStartChange();
    this.timeEndChange();
  }

  timeStartChange() {
    if (this.storageHistory.TimeStartIn || this.storageHistory.TimeEndIn) {
      this.timeSpentInDisabled = true;

      const start = moment(this.storageHistory.TimeStartIn, 'HH:mm');
      const end = moment(this.storageHistory.TimeEndIn, 'HH:mm');
      const hours = end.diff(start, 'hours');
      const mins = moment.utc(end.diff(start)).format('mm');
      const differenceString = moment.utc(end.diff(start)).format('HH:mm');

      if (hours < 0 || hours + +mins <= 0) {
        this.storageHistory.TimeSpentIn = null;
      } else {
        this.storageHistory.TimeSpentIn = differenceString as any;
      }
    } else {
      this.timeSpentInDisabled = false;
    }
  }

  timeEndChange() {
    if (this.storageHistory.TimeStartOut || this.storageHistory.TimeEndOut) {
      this.timeSpentOutDisabled = true;

      const start = moment(this.storageHistory.TimeStartOut, 'HH:mm');
      const end = moment(this.storageHistory.TimeEndOut, 'HH:mm');
      const hours = end.diff(start, 'hours');
      const mins = moment.utc(end.diff(start)).format('mm');
      const differenceString = moment.utc(end.diff(start)).format('HH:mm');

      if (hours < 0 || hours + +mins <= 0) {
        this.storageHistory.TimeSpentOut = null;
      } else {
        this.storageHistory.TimeSpentOut = differenceString as any;
      }
    } else {
      this.timeSpentOutDisabled = false;
    }
  }

  private async save() {
    try {
      const data: any = {
        Title: this.storageHistory.Title,
        Comment: this.storageHistory.Comment,
        In: this.storageHistory.In,
        Out: this.storageHistory.Out,
        ServiceStationStorageId: this.storageHistory.ServiceStationStorageId,

        TimeStartIn: this.storageHistory.TimeStartIn,
        TimeEndIn: this.storageHistory.TimeEndIn,
        TimeSpentIn: moment.duration(this.storageHistory.TimeSpentIn).asMinutes(),
        TimeStartOut: this.storageHistory.TimeStartOut,
        TimeEndOut: this.storageHistory.TimeEndOut,
        TimeSpentOut: moment.duration(this.storageHistory.TimeSpentOut).asMinutes(),
      };
      await this.storageHistoryService.put(data, this.storageHistory.Id);
      this.toastService.showSuccess('storages.saved');
      await this.dialogController.ok();
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
