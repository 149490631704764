import { SpecialProductTypeService } from './../../services/special-product-type-service';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine, observable, resolver } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { activationStrategy, Router } from 'aurelia-router';
import $ from 'jquery';
import { Models } from 'models/core';
import { UserModels } from 'models/UserModels';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { NetService } from 'services/net-service';
import { NetStatusService } from 'services/net-status-service';
import { ProducerService } from 'services/producer-service';
import { UserService } from 'services/user-service';
import { AuthorizeStep } from './../../authorizeStep';
import { roles } from './../../routes';

import * as moment from 'moment';
import { ListSettingsService } from 'services/list-settings-service';
import { NetTypeService } from 'services/net-type-service';
import { StorageHistoryService } from 'services/storage-history-service';
import { DialogService } from 'aurelia-dialog';
import { StorageInvoiceDialog } from './storage-invoice-dialog';
import { ServiceStationService } from 'services/service-station-service';
import { StorageDetailsDialog } from './storage-details-dialog';
const SELECTED_STORAGE_COLUMNS = 'SELECTED_STORAGE_COLUMNS';

@autoinject
export class StorageList {
  private tableData: Array<any>;
  private ready: boolean;
  private params: any;
  private userData: UserModels.User;
  private totalItems: number = 15;
  private netTypes: Array<Models.NetType>;
  private specialProductTypes: Array<Models.SpecialProductType>;

  @bindable private currentPage: number = 1;
  private customerid;
  private context: string;

  private selectedFields: Array<any>;
  private STORAGE_FILTERS_KEY = 'STORAGE_FILTERS_KEY';
  private showSearchDefault: boolean = false;

  private fields = null;

  private tableViewType: number = 1;
  private tableViewVerticalLines: boolean = false;
  private tableMargin: number = 2;
  private pageSize: number = 25;
  private searchTextSubscription: Subscription;

  private isSpecialProductList: boolean = false;

  private showNetFilters: boolean = true;
  private netFiltersList: Array<any> = [];
  @bindable private filtersVisible: any = null;
  @bindable private hideNetFilters: boolean = false;

  private netTypeCheckboxes = [];
  private netTypeCheckboxesObserver: any;

  private specialProductTypeCheckboxes = [];
  private specialProductTypeCheckboxesObserver: any;

  // FILTERS
  private storageFilters: any;
  private filterGetFiltersQuery: any;
  private filterToggleVisible: any;
  private filterClearAll: any;

  private invoiceSelected: any[] = [];

  private selectedCustomerId: number;
  private serviceStation: Models.ServiceStation;
  private serviceStationId: number;
  private serviceStations: Models.ServiceStation[];

  constructor(
    private netService: NetService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private netStatusService: NetStatusService,
    private userService: UserService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private producerService: ProducerService,
    private bindingEngine: BindingEngine,
    private i18n: I18N,
    private listSettingsService: ListSettingsService,
    private netTypeService: NetTypeService,
    private specialProductTypeService: SpecialProductTypeService,
    private storageHistoryService: StorageHistoryService,
    private dialogService: DialogService,
    private serviceStationService: ServiceStationService
  ) {}

  private getFilterKey() {
    return this.STORAGE_FILTERS_KEY;
  }

  private activate(params) {
    this.params = params;
  }

  private attached(params) {
    this.ready = false;

    if (!this.params && params) {
      this.params = params;
    } else if (!params && this.params) {
      params = this.params;
    }
    if (!this.params) {
      this.params = {};
      params = {};
    }

    Promise.all([
      this.userService.getCurrentUser(),
      this.listSettingsService.getDefaultPageSize(),
      this.serviceStationService.getAllCached(),
    ])
      .then((responses) => {
        this.userData = responses[0];

        this.fields = this.getFields();

        // get selected values from localstorage
        const selectedFields: Array<string> = JSON.parse(localStorage.getItem(SELECTED_STORAGE_COLUMNS));

        if (selectedFields) {
          this.fields.forEach((field) => {
            if (field.visible) {
              field.selected = false;
            }
          });

          selectedFields.forEach((selectedField) => {
            const field = this.fields.find((x) => x.field === selectedField);
            if (field) {
              field.selected = true;
            } else {
              field.selected = false;
            }
          });
        }

        // list-settings
        this.pageSize = responses[1];

        // Servicestations
        this.serviceStationId = this.userData.ServiceStationId;
        this.serviceStations = responses[2].filter((x) => !x.IsDeleted).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (!this.serviceStationId) {
          this.changeServiceStation(this.serviceStations[0]);
        } else {
          let serviceStation = this.serviceStations.find((x) => x.Id === this.serviceStationId);
          this.changeServiceStation(serviceStation);
        }

        this.getStorageHistory();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private determineActivationStrategy() {
    return activationStrategy.replace;
  }

  private buildQueryString(obj) {
    const str = [];
    for (const p in obj) {
      if (!p.startsWith('_') && obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  private pushState() {
    const baseUrl = '#' + this.router.baseUrl + '/storages';
    const stateObj = {
      currentPage: this.currentPage,
    };
    const queryString = this.buildQueryString(stateObj);
    history.pushState(stateObj, 'storages', baseUrl + '?' + queryString);
  }

  private sortTable(field) {
    if (!this.storageFilters) {
      return;
    }

    if (this.storageFilters.orderBy === field.field) {
      this.storageFilters.orderByDirection = this.storageFilters.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.storageFilters.orderBy = field.field;
      this.storageFilters.orderByDirection = 'DESC';
    }

    this.pushState();
    this.saveFilters();
    this.getStorageHistory();
  }

  private currentPageChanged(newValue, oldValue) {
    if (!this.storageFilters) {
      // cannot change page, netFilters not defined
      return;
    }

    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    this.pushState();
    this.storageFilters.skip = (+newValue - 1) * (this.storageFilters.top ?? this.pageSize);
    this.getStorageHistory();
  }

  private getFields() {
    return [
      {
        field: 'Id',
        title: 'general.id',
        selected: true,
        disabled: true,
        visible: false,
      },
      {
        field: 'NetIsDiscarded',
        title: 'general.discarded',
        selected: true,
        disabled: false,
      },
      { field: 'NetId', title: 'general.id', selected: true, disabled: false },
      {
        field: 'CustomerName',
        title: 'general.customer',
        selected: true,
        disabled: false,
      },
      {
        field: 'Title',
        title: 'storages.title',
        selected: true,
        disabled: false,
      },
      {
        field: 'Comment',
        title: 'storages.comment',
        selected: false,
        disabled: false,
      },
      {
        field: 'SpecialProductTypeName',
        title: 'notlogg.netType',
        selected: true,
        disabled: false,
      },
      {
        field: 'ServiceStationStorageName',
        title: 'service.storagePlacement',
        titleShort: 'shortTitle.service.storagePlacement',
        selected: true,
        disabled: false,
      },
      {
        field: 'In',
        title: 'storages.inDate',
        selected: true,
        isDate: true,
        disabled: false,
      },
      {
        field: 'Out',
        title: 'storages.outDate',
        selected: true,
        isDate: true,
        disabled: false,
      },
      {
        field: 'DaysInStorage',
        title: 'general.days',
        selected: true,
        disabled: false,
      },
      {
        field: 'Invoiced',
        title: 'storages.invoiced',
        selected: true,
        disabled: false,
      },
    ];
  }

  private async clearFilters() {
    await this.filterClearAll();
  }

  private async getStorageHistory() {
    if (this.fields == null) {
      this.fields = this.getFields();
    }

    this.selectedFields = this.fields.filter((x) => x.selected && (x.visible === undefined || x.visible === true));

    // Needs to be inside a timeout because query-function
    // is binded in from net-filters
    setTimeout(async () => {
      let filtersQuery: any = await this.filterGetFiltersQuery(this.fields);

      setTimeout(() => {
        if (!this.searchTextSubscription) {
          this.searchTextSubscription = this.bindingEngine
            .propertyObserver(this.storageFilters, 'searchText')
            .subscribe((newValue, oldValue) => {
              this.saveFilters();
              this.getStorageHistory();
            });
        }

        filtersQuery._export = false;
        filtersQuery._select =
          this.fields
            .filter((x) => x.selected)
            .map((x) => x.field)
            .join(',') + ',TimeStartIn,TimeEndIn,TimeSpentIn,TimeStartOut,TimeEndOut,TimeSpentOut';

        filtersQuery.top = this.pageSize;
        filtersQuery.skip = this.storageFilters.skip;
        filtersQuery.orderBy = this.storageFilters.orderBy;
        filtersQuery.orderByDirection = this.storageFilters.orderByDirection;
        filtersQuery.serviceStationId = this.serviceStationId;

        this.setAdditionalFilters(filtersQuery);

        this.storageHistoryService
          .getItems(filtersQuery)
          .then((res) => {
            res.json().then((data) => {
              this.tableData = data;
            });

            this.totalItems = res.headers.get('x-total-count');
            this.tableData = res.data;
            this.ready = true;
          })
          .catch((err) => {
            this.ready = true;
            this.errorService.handleError(err);
          });
      });
    }, 150);
  }

  private setAdditionalFilters(netFiltersQuery) {
    if (!!this.customerid) {
      netFiltersQuery.customerId = this.customerid;
    }
    if (!!this.storageFilters.searchText) {
      netFiltersQuery.searchText = this.storageFilters.searchText;
    }
  }

  private saveFilters() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.storageFilters)) {
      const filter = this.storageFilters[key];
      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    data.orderBy = this.storageFilters.orderBy;
    data.orderByDirection = this.storageFilters.orderByDirection;
    data.top = this.storageFilters.top ?? this.pageSize;
    data.skip = this.storageFilters.skip;

    localStorage.setItem(this.getFilterKey(), JSON.stringify(data));
  }

  private updateSelectList(event, field) {
    const selectedFields = this.fields.filter((x) => x.selected).map((x) => x.field);
    localStorage.setItem(
      SELECTED_STORAGE_COLUMNS + (this.context ? '_' + this.context : ''),
      JSON.stringify(selectedFields)
    );
    this.getStorageHistory();
  }

  private rowClick(row: any, event) {
    if (event?.target.tagName !== 'INPUT') {
      this.dialogService
        .open({
          viewModel: StorageDetailsDialog,
          model: { storageHistory: row },
          lock: false,
          keyboard: true,
        })
        .whenClosed((res) => {
          if (!res.wasCancelled) {
            this.getStorageHistory();
          }
        });
    }
    return event;
  }

  private onCheckboxChange(row, event) {
    if (event.target.checked) {
      this.selectedCustomerId = row.CustomerId;
    } else {
      if (this.invoiceSelected.length === 0) {
        this.selectedCustomerId = null;
      }
    }
  }

  private toggleSelectAll() {
    const tableTotalCount = this.tableData.filter((it) => !it.Invoiced).length;
    if (this.invoiceSelected.length >= 0 && this.invoiceSelected.length < tableTotalCount) {
      const ids = this.tableData.filter((it) => !it.Invoiced).map((it) => it.Id);
      this.invoiceSelected = ids;
    } else {
      this.invoiceSelected = [];
    }
  }

  private openInvoiceDialog() {
    const selectedItems = this.tableData.filter((it) => this.invoiceSelected.includes(it.Id));
    this.dialogService
      .open({
        viewModel: StorageInvoiceDialog,
        model: { selectedItems, serviceStationId: this.serviceStationId },
        lock: true,
        overlayDismiss: false,
        keyboard: false,
        position: (mc, mo) => {},
      })
      .whenClosed((res) => {
        if (!res.wasCancelled) {
          this.getStorageHistory();
          this.invoiceSelected = [];
        }
      });
  }

  changeServiceStation(serviceStation) {
    this.serviceStationId = serviceStation.Id;
    this.serviceStation = serviceStation;
    this.currentPage = 1;
    this.eventAggregator.publish('dropdownClose', 1);
    setTimeout(() => this.eventAggregator.publish('storageFilterOptionsRefresh', 'storageIds'));
    if (this.tableData) {
      this.getStorageHistory();
    }
  }

  private async exportStorageList() {
    let filtersQuery: any = await this.filterGetFiltersQuery(this.fields);
    this.setAdditionalFilters(filtersQuery);

    filtersQuery._select = this.fields
      .filter((x) => x.selected)
      .map((x) => x.field)
      .join(',');
    filtersQuery._export = true;
    filtersQuery.serviceStationId = this.serviceStationId;

    this.storageHistoryService
      .getItems(filtersQuery)
      .then((res) => {
        this.ready = true;
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
